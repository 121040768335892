/**
 * Set Default Season Range
 * @returns {Boolean} - if true = update verbiage
 */
const today = new Date();
const year = today.getFullYear();
export default function checkForSeasonalUpdates(trade) {
	if (trade === 'hvac') {
		/* Default Hightlight Cooling Services; Highlight Heating Services from September 25 to March 14 */
		const springDate = new Date(`03-14-${year}`);
		const fallDate = new Date(`09-25-${year}`);

		return !(today > springDate && today < fallDate);
	}
}
