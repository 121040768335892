/**
 * Creates the checkboxes / wrappers for consent capture
 * @param {Object} vendors - response from the API
 * @param {String} targetDiv - div to append the checkboxes to
 */
export default function renderConsentCheckboxes(vendors, targetDiv) {
	const modernizeTargetDiv = document.querySelector('[data-modernize-consent]');

	for (let i = 0; i < vendors.length; i++) {
		const vendorDisplayName = vendors[i].vendorDisplayName.trim();
		const checkboxWrapper = document.createElement('span');
		checkboxWrapper.className = 'checkbox';

		const label = document.createElement('label');
		const input = document.createElement('input');
		input.className = 'consent-capture';
		input.type = 'checkbox';
		input.setAttribute('data-name',vendorDisplayName);
		input.setAttribute('data-key', vendors[i].vendorKey);
		input.setAttribute('checked', 'checked');

		label.appendChild(input);
		label.appendChild(document.createTextNode(vendorDisplayName));

		checkboxWrapper.appendChild(label);

		if (modernizeTargetDiv && vendorDisplayName === 'Modernize') {
			modernizeTargetDiv.innerHTML = checkboxWrapper.outerHTML;
		} else if (targetDiv) {
			// Hardcoding styles for ongoing tests with live matching (super temp solution, sorry in advance)
			label.style.cssText = 'display:inline;white-space:break-spaces;padding:0';
			input.insertAdjacentHTML('afterEnd', '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
			targetDiv.appendChild(checkboxWrapper);
		}
	}
}
